import React, { Component } from "react";
import AboutUsBuilder from '../../components/AboutUsBuilder/AboutUsBuilder';
import Header from "../../components/Header/Header";

class AboutUs extends Component {

  render() {


    return (
      <div>
        <Header />
        <AboutUsBuilder></AboutUsBuilder>
       
      </div>

    );
  }
}

export default AboutUs;
