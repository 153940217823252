import React, { Component } from 'react';

import './App.css';
import { Route, Switch } from 'react-router-dom';
import About from './container/AboutUs/AboutUs';
import Home from './container/Home/Home';
import AppShowOff from './container/AppShowOff/AppShowOff';
import RunTracker from './container/RunTracker/RunTracker';
import NotFound from './container/NotFound/NotFound';
import PrivacyPolicy from './container/PrivacyPolicy/PrivacyPolicy';
class App extends Component {
  render() {
    return (
      <div>
  
        <Switch>
          <Route path="/" exact component={Home}/> 
          <Route path="/home" exact component={Home}/>
          <Route path="/about" exact component={About}/>
          <Route path="/15_days_home_workout" exact component={AppShowOff}/>
          <Route path="/my_run_tracker" exact component={RunTracker}/>
          <Route path="/privacy_policy" exact component={PrivacyPolicy}/>
          <Route path="*" component={NotFound}/>        
          </Switch>
       
        
      </div>
    );
  }
}


export default App;
