import React from "react";

import WORKOUT_BANNER from "../../assets/home_workout_banner.png";
import HOME_WORKOUT_BANNER from "../../assets/bg_run_tracker_three.png";
import OTHER_FEATURE_BANNER from "../../assets/workout_other_feature_banner.png";
import LOGO from "../../assets/logo.png";
import RUNNING_BANNER from '../../assets/run_tracker_banner.png'
import Leaderboard_BANNER from '../../assets/leaderboard_banner.png'

class RunTrackerBuilder extends React.Component {




    render() {
        return (

            <div className="container-fluid"  >
                <div className="row my-5">

                    <div className="col-sm">
                        <img src={RUNNING_BANNER} style={{ height: "560px", maxWidth: "100%" }}></img>
                    </div>
                    <div className="col-sm mt-2">
                        <h2 className="mt-5 m-3 font-weight-bold" style={{ color: "#8E0000" }}>My Run Tracker</h2>
                        <h4 className="mt-2 m-3 ">Run.Track</h4>
                        <p className="m-3 mb-4 font-weight-normal ">Are your ready to be fit? Put your tracks and running shoes on &get ready to experience the fittest phase of your life. Download the best run tracking app to get started.

My Run Tracker, is the app that will be an ally to you in this journey. You can track the activities you do for the weight loss or for just being fit. My Run Tracker tracks your training using GPS and analyzes everything related, from the number of steps you run to the distance you covered and to the calories you have burned.<span><a href="https://play.google.com/store/apps/details?id=activity.com.myactivity2"> Read More</a></span></p>

                        <a href='https://play.google.com/store/apps/details?id=activity.com.myactivity2&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="my-4" style={{ width: "240px" }} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                    </div>

                </div>


                <div className="container-fluid bg-dark my-4 d-flex" style={{ height: "360px" }}>
                    <div className="container d-flex align-items-center">
                        <div className="row ">
                            <div className="col-sm">
                                <h1 className="display-1 text-white font-weight-bold">1Lacs +</h1>
                                <h1 className="display-4 text-white font-weight-bold">Downloads</h1>
                            </div>
                            <div className="col-sm d-flex align-items-center">
                                <p className="text-white mb-0"> “The miracle isn’t that I finished. The miracle is that I had the courage to start.” – John Bingham</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-white d-flex justify-content-center">
                    <h4 className="mt-4">Lets loose some weight... <span style={{ color: "#8E0000" }}>What say??</span> </h4>
                </div>
                <div className="row bg-white d-flex justify-content-center">
                    <h6 className="mt-4 text-secondary mx-2" style={{ maxWidth: '720px' }}>Are your ready to be fit? Put your tracks and running shoes on &get ready to experience the fittest phase of your life. Download the best run tracking app to get started.</h6>
                </div>
                <div className="row bg-white d-flex justify-content-center">
                    <img src={HOME_WORKOUT_BANNER} style={{  maxWidth: "100%" }}></img>
                </div>

                <div className="container-fluid my-4 d-flex align-items-center" style={{ height: "360px", backgroundColor: "#8E0000" }}>
                  
                        <p className="text-white mb-0 mx-auto"> “What seems hard now will one day be your warm-up.” – Unknown</p>
                    
                </div>
                <div className="row my-5">

                    <div className="col-sm d-flex justify-content-center">
                        <img src={Leaderboard_BANNER} style={{ height: "560px",maxWidth: "100%" }}></img>
                    </div>
                    <div className="col-sm mt-2">
                        <h2 className="mt-5 m-3 font-weight-bold" style={{ color: "#8E0000" }}>Leadboards</h2>
                        <p className="m-3 mb-4 font-weight-normal "> Daily: Who would't love to feature in the list of top 20 people who burnt the maximum number of calories on a particular day using our platform? Well let me assure you that it feels good to see our name there. I have felt it first hand :p</p>
                        <p className="m-3 mb-4 font-weight-normal "> Overall: Our platform also keeps a track of the people who have been consistent throughout and there is a separate list for that. If you are not consistent with your workout routine then it is not effective.</p>

                    </div>

                </div>
                <div className="container-fluid bg-light my-4 d-flex" style={{ height: "720px" }}>
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-sm">
                                <h1 className="display-4 text-dark font-weight-bold">Other Features</h1>
                            </div>
                            <div className="col-sm d-flex justify-content-center">
                                <img style={{ height: "560px",maxWidth: "100%" }} src={OTHER_FEATURE_BANNER}></img>
                            </div>
                        </div>
                    </div>
                </div>
               


            </div>
        );
    }
}
export default RunTrackerBuilder;

