import React from 'react';
import LOGO from '../../assets/logo.png';

class Footer extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {


        return (
            <footer class="bg-light text-center text-lg-start">

              <div class="text-center p-3" style={{backgroundColor: "#fff" }}>
    
                   <a class="text-dark" href="https://thelosers.in/">@2021 The Losers </a>
                   <a>.</a>
                
                   <a  href="https://thelosers.in/privacy_policy"> Privacy Policy</a>
                   
                </div>
           
        </footer>

        );
    }
}

export default (Footer);
