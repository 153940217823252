import React, { Component } from "react";

class NotFound extends Component {
  
  render() {
    

    return (
        <div className="container">
        <p className="notFoundText">404 Page not found !!</p>

        </div>
    );
  }
}

export default NotFound;
