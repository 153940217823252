import React, { Component } from "react";
import PrivacyPolicyBuilder from '../../components/PrivacyPolicyBuilder/PrivacyPolicyBuilder';
import Header from '../../components/Header/Header';
class PrivacyPolicy extends Component {
  
  render() {
    
    return (
        <div>
            <Header/>
            <PrivacyPolicyBuilder></PrivacyPolicyBuilder>
        </div>
       
    );
  }
}

export default PrivacyPolicy;
