import React from "react";
import classes from "./HomeBuilder.css";
import Typist from 'react-typist';

import YOUTUBE_BANNER from '../../assets/hey_losers.png';



class HomeBuilder extends React.Component {

  state = {
    renderMsg: false,
  }

  onHeaderTyped = () => {
    this.setState({ renderMsg: true });
  }

  render() {
    return (

      <div className="container-fluid" style={{ height: "1080px" }} >
        <div className="container d-flex align-items-center">
          <img className="my-4" src={YOUTUBE_BANNER} style={{ maxWidth: "100%" }}></img>
        </div>
        <div className="d-flex align-items-end justify-content-center container" style={{ height: "920px" }}>
         
            <a className="mb-0 text-primary text-center" href="https://www.youtube.com/channel/UCpt5BC1XnbGRq2YS2rI9TnA?sub_confimation=1">Subscribe to my YouTube Channel</a>
         
            <p className="mb-0 text-secondary text-center" > There will be haters, there will be doubters, there will be non-believers, and then there will be you, proving them wrong. - Jennifer Van Allen</p>
          
            <a className="mb-0 text-primary text-center d-inline" href="https://thelosers.in/privacy_policy" >Privacy Policy</a>
          

        </div>

      </div>


      /* <Typist
        className="typing"
        cursor={{ hideWhenDone: true }}
        avgTypingDelay={100}
        startDelay={2000}
        onTypingDone={this.onHeaderTyped}
      >
        <a >I'm a coder</a>
        <Typist.Backspace count={11} delay={1000} />
        <a >Sorry !!!</a>
        <Typist.Backspace count={9} delay={1000} />
        <a >I'm a loser.</a>
      </Typist>
      {this.state.renderMsg ? (
        <div>
          <p className="followMeText">follow me</p>
          <div className="container my-auto followMeIcon">
            <div className="row justify-content-center align-self-center">
              <a href='https://github.com/the-losers'>
              <img style={{ width: '36px', height: '36px' }} className="mx-2" src={github}></img>
              </a>
              <a href='https://play.google.com/store/apps/details?id=activity.com.myactivity2'>
              <img style={{ width: '36px', height: '36px' }} className="mx-2" src={playstore}></img>

              </a>
              <a href='https://itunes.apple.com/us/app/my-run-tracker/id1361182095?ls=1&mt=8'>
              <img style={{ width: '36px', height: '36px' }} className="mx-2" src={appstore}></img>
             
              </a>
              <a href='https://www.linkedin.com/in/deepak-kumar-9812a3aa/'>
              <img style={{ width: '36px', height: '36px' }} className="mx-2" src={linkedin}></img>

              </a>
             
             <a href='https://medium.com/@deepakypro/how-to-plot-a-graph-in-real-world-using-arcore-android-4f5f1007fb86'>
             <img style={{ width: '36px', height: '36px' }} className="mx-2 my-1" src={medium}></img>
             </a>
               
              
            </div>
          </div>
        </div>) : null} */



    );
  }
}
export default HomeBuilder;

