import React, { Component } from "react";
import HomeBuilder from '../../components/HomeBuilder/HomeBuilder';
import Header from '../../components/Header/Header';
class Home extends Component {
  
  render() {
    

    return (
      <div>
        <Header></Header>
        <HomeBuilder></HomeBuilder>
      </div>
       
    );
  }
}

export default Home;
