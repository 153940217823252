import React from 'react';
import LOGO from '../../assets/logo.png';

class Header extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        let logo = this.props.logo;
        let width = "56px";
        let home_link = this.props.home_link;
        if(logo == null){
            logo = LOGO;
            width = "160px";
            home_link = "https://thelosers.in/";
        }

        return (
            <div>

                <nav className="navbar navbar-expand-lg  navbar-light bg-light shadow-sm ">
                    <nav className="navbar navbar-light">
                        <a className="navbar-brand" href={home_link}>
                            <img src={logo} width={width} alt="Home icon" />
                        </a>
                    </nav>
                
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item  ">
                                <a className="nav-link  text-dark" href="/home">Home </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link  text-dark" href="/my_run_tracker">My Run Tracker</a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link  text-dark" href="/15_days_home_workout">Home Workout</a>
                            </li>
                            <li className="nav-item  text-secondary">
                                <a className="nav-link  text-dark" href="/about">About</a>
                            </li>

                            <li className="nav-item  ">
                                <a className="nav-link  text-dark" >Email me - <spain className="text-danger">team@thelosers.in</spain></a>
                            </li>


                        </ul>

                    </div>
                </nav>

            </div>

        );
    }
}

export default (Header);
