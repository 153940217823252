import React from "react";


class PrivacyPolicyBuilder extends React.Component {

  
  render() {
    return (

      <div className="container-fluid"  >
      
    <strong className="mt-5" >Privacy Policy</strong> <p>
     The losers(“My Run Tracker”, "15 Days Home workout", “we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used and disclosed by The Losers. This Privacy Policy applies to our application named My Run Tracker, 15 Days Home workout and its associated subdomains (collectively our “service”). By accessing or using our Service, you signify that you have read, understood and agree to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy and our Terms of Service
                </p> 
                 <p>Please read this Privacy Policy carefully, as your access to and use of the Services signifies that you have read, understand, and agree to all terms within this Privacy Policy. If you do not agree with any part of this Privacy Policy or our Terms, please do not access or continue to use any of the Services or otherwise submit your Personal Data.  
                 </p>

                 <p><strong>WHO WE ARE</strong></p> 
                 <p>We are the losers (collectively referred to herein as “My Run Tracker”, "15 Days Home workout", “we,” “us,” or “our”). We are on a mission to provide a better fitness app to our users. Apart from this we have a second app named “15 days home workout” which is also live in play store.</p>

                 <p><strong>HOW WE COLLECT AND USE PERSONAL DATA</strong></p> 
                 <p>We collect your Personal Data in a number of ways and for various purposes, including:</p>
                 
                 <p>1. When You Register For an Account or Interact With Our Services. (Applicable only in My Run Tracker)</p>
                 <p>We collect Personal Data when you use or interact with our Services, including when you create an account with us. This Personal Data may include name,  email address, profile image. 
                 We use the collected data to create your account, enable your activity within our Services, and to provide the services generally, including developing, enhancing, and improving our Services and your experience. We also use this data for internal purposes related to certain research, analytics, monitoring, customer communication, risk management, and administrative purposes.</p>
        
                 <p>2. When You Input Fitness Data Within Our Services or Use Our Services That Collect Such Data From Mobile Device Sensors. (Applicable for both My Run Tracker and 15 Days Home workout)</p> 
                 <p>“Fitness Data” includes data related to your fitness, height, weight all are stored in your phone and will be deleted when you uninstall the app. We don’t store any personal data related to your health on our server.</p>
                
                 <p>3. When You Access Third Party Products and Services. (Applicable for both My Run Tracker and 15 Days Home workout)</p>
                 <p>We may allow you to register and interact with third party products and services, another website, mobile application, or Internet locations (collectively “Third Party Sites”) through our Services, and we may collect Personal Data that you share with Third Party Sites through our Services. When we do so, we will inform you of the further details of how we use your Personal Data.</p>
                
                 <p>4. When You Give Us Permission to Collect Location Data. (Applicable only in My Run Tracker)</p>
                 <p>We may collect precise Location Data as part of the functionality of our Services. We may collect Location Data in several ways, such as through your wireless carrier, based on WiFi access point location,  directly from the device on which you use the Services. All your location related data is stored in your phone and will be deleted when you uninstall the app. In all events, we do not collect Location Data, unless you have “allowed” its collection.</p>
                
                 <p>5. When you complete a run and press save Run. (Applicable only in My Run Tracker)</p>
                 <p>We collect your data when you press save run . Data includes your distance, name, email, run count, profile image.  We use this data for leaderboard purpose.</p>
                  
                 <p>6. When you Register for a Pedometer Service. (Applicable for both My Run Tracker and 15 Days Home workout)</p>
                 <p>We are using Google Fitness api to get your health data including Steps, Calories and distance. We are not storing any data on our end or even at your phone. We are using that data to show details in more presentable way. </p>
                  
                <p><strong>LOCATION DATA</strong></p>
                <p>Our Mobile Apps transmit precise Location Data. Location Data may be obtained from GPS services, cell tower triangulation, or known location of nearby WiFi access points. You may be able to turn off the transmission of precise Location Data and Device IDs by turning off those services using your mobile device’s built-in settings. Turning off location may cause some of our Services not to work.</p>


                  <p><strong>DATA RETENTION</strong></p>
                  <p>Currently we don't provide 'Delete my Account' functionality to the users. If you want to delete your data then you can contact us as at team@thelosers.in</p>

                 <p><strong>Information Collection and Use</strong></p> <p>
                  For a better experience, while using our Service, I
                  may require you to provide us with certain personally
                  identifiable information, including but not limited to Email, Name. The information that
                  I request will be retained on your device and is not collected by me in any way.
                </p> <div><p>
                    The app does use third party services that may collect
                    information used to identify you.
                  </p> <p>
                    Link to privacy policy of third party service providers used
                    by the app
                  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li></ul></div> <p><strong>Log Data</strong></p> <p>
                  I want to inform you that whenever you
                  use my Service, in a case of an error in the app
                  I collect data and information (through third party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing my Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p> <p><strong>Cookies</strong></p> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <p><strong>Service Providers</strong></p> <p>
                  I may employ third-party companies and
                  individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  I want to inform users of this Service
                  that these third parties have access to your Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                  I value your trust in providing us your
                  Personal Information, thus we are striving to use commercially
                  acceptable means of protecting it. But remember that no method
                  of transmission over the internet, or method of electronic
                  storage is 100% secure and reliable, and I cannot
                  guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by me.
                  Therefore, I strongly advise you to review the
                  Privacy Policy of these websites. I have
                  no control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p>
                <p><strong>WHAT DATA IS COLLECTED FROM MINORS?</strong></p>
                <p>We do not knowingly collect Personal Data online from children under 13 (note that the minimum age may vary based on country/region, and on local law). If you become aware that a child has provided us with Personal Data without parental consent, please contact us via email at team@thelosers.in. If we become aware that a child under 13 has provided us with Personal Data without parental consent, we will take steps to remove the data and cancel the child’s account.</p>

                <p><strong>YOUR CALIFORNIA ONLINE PRIVACY RIGHTS</strong></p>
                <p>We permit residents of California to use our Service. Therefore, it is our intent to comply with the California Business and Professions Code §§ 22575-22579. If you are a California resident, you may request certain information regarding our disclosure of personal information to any third parties for their direct marketing purposes. You may contact us at team@thelosers.in with any questions.</p>


                 <p><strong>Children’s Privacy</strong></p> <p>
                  These Services do not address anyone under the age of 13.
                  I do not knowingly collect personally
                  identifiable information from children under 13. In the case
                  I discover that a child under 13 has provided
                  me with personal information, I immediately
                  delete this from our servers. If you are a parent or guardian
                  and you are aware that your child has provided us with
                  personal information, please contact me so that
                  I will be able to do necessary actions.
                </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                  I may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. I will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2018-01-27</p> <p><strong>Contact Us</strong></p> <p>
                  If you have any questions or suggestions about my
                  Privacy Policy, do not hesitate to contact me at team@thelosers.in.
                </p>
                <p><strong>AMENDMENTS</strong></p>
                <p>We may amend this Privacy Policy from time to time. When we amend this Privacy Policy, we will post the changes here. Your continued use of our Service after an amended Privacy Policy is posted shall constitute your acceptance of such changes.</p>
   
    
      
      </div>
    );
  }
}
export default PrivacyPolicyBuilder;

