import React, { Component } from "react";
import AppShowOffBuilder from '../../components/AppShowOffBuilder/AppShowOffBuilder';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LOGO from '../../assets/home_workout_logo.png';
class AppShowOff extends Component {
  
  render() {
    

    return (
        <div>
            <Header logo={LOGO} home_link="https://play.google.com/store/apps/details?id=com.thelosers.homeworkout"/>
            <AppShowOffBuilder></AppShowOffBuilder>
            <Footer/>
        </div>
       
    );
  }
}

export default AppShowOff;
