import React from "react";

import WORKOUT_BANNER from "../../assets/home_workout_banner.png";
import HOME_WORKOUT_BANNER from "../../assets/workout_ss_banner.png";
import OTHER_FEATURE_BANNER from "../../assets/workout_other_feature_banner.png";
import LOGO from "../../assets/logo.png";

class AppShowOffBuilder extends React.Component {




    render() {
        return (

            <div className="container-fluid"  >

                <div className="row my-5">
                    <div className="col-sm mt-2">
                        <h2 className="mt-5 m-3 font-weight-bold" style={{ color: "#8B4D7E" }}>15 Days Home Workout</h2>
                        <h4 className="mt-2 m-3 ">Free personal 15 days Home workout app.</h4>
                        <p className="m-3 mb-4 font-weight-normal ">Don't let being locked down at home stop you from having a fit & healthy body. We have brought you just the app that will help you achieve your fitness goals at home.
                        15 Days Home Workout Challenge app provides you with daily workout routines. Just a little time out of your daily schedule and you can build muscles and keep yourself fit while you are at home.
Our workout challenges includes only body-weight workouts and requires no exercise equipment or coach advice, all exercises can be performed at home and with just your body weight.<span><a href="https://play.google.com/store/apps/details?id=com.thelosers.homeworkout"> Read More</a></span></p>

                        <a href='https://play.google.com/store/apps/details?id=com.thelosers.homeworkout&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="my-4" style={{ width: "240px" }} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                        <a href="https://taimienphi.vn/download-15-days-home-workout-challenge-90340" title="download"><img className="my-4" src="https://taimienphi.vn/Images/bn/reviewed/tmp2.png" title="awarded 5 Stars at Taimienphi" alt="download" /></a>
                    </div>
                    <div className="col-sm ">
                        <img className="float-right" src={WORKOUT_BANNER} style={{ height: "560px",maxWidth: "100%" }}></img>
                    </div>
                </div>

                <div className="container-fluid bg-dark my-4 d-flex" style={{ height: "360px" }}>
                    <div className="container d-flex align-items-center">
                        <div className="row ">
                            <div className="col-sm">
                                <h1 className="display-1 text-white font-weight-bold">50k +</h1>
                                <h1 className="display-4 text-white font-weight-bold">Downloads</h1>
                            </div>
                            <div className="col-sm d-flex align-items-center">
                                <p className="text-white mb-0"> “The last three or four reps is what makes the muscle grow. This area of pain divides a champion from someone who is not a champion.”
                            — Arnold Schwarzenegger, seven-time Mr. Olympia</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-white d-flex justify-content-center">
                    <h4 className="mt-4">A Simple way to <span style={{ color: "#8B4D7E" }}>lose some weight</span> </h4>
                </div>
                <div className="row bg-white d-flex justify-content-center">
                    <h6 className="mt-4 text-secondary mx-2" style={{ maxWidth: '720px' }}>15 Days Home Workout Challenge app provides you with daily workout routines. Just a little time out of your daily schedule and you can build muscles and keep yourself fit while you are at home. </h6>
                </div>
                <div className="row bg-white d-flex justify-content-center">
                    <img style={{ maxWidth: "100%" }} src={HOME_WORKOUT_BANNER}></img>
                </div>
                <div className="container-fluid bg-light my-4 d-flex" style={{ height: "720px" }}>
                    <div className="container my-auto">
                        <div className="row ">
                            <div className="col-sm">
                                <h1 className="display-4 text-dark font-weight-bold">Other Features</h1>
                            </div>
                            <div className="col-sm d-flex justify-content-center">
                                <img style={{ height: "560px",maxWidth: "100%" }} src={OTHER_FEATURE_BANNER}></img>
                            </div>
                        </div>
                    </div>
                </div>
              
                {/* <div className="row">

                    <div className="col-sm">
                        <img src={RUNNING_BANNER} style={{ height: "560px" }}></img>
                    </div>
                    <div className="col-sm mt-2">
                        <h2 className="mt-5 m-3 font-weight-bold" style={{ color: "#8E0000" }}>My Run Tracker</h2>
                        <h4 className="mt-2 m-3 ">Run.Track</h4>
                        <p className="m-3 mb-4 font-weight-normal ">Are your ready to be fit? Put your tracks and running shoes on &get ready to experience the fittest phase of your life. Download the best run tracking app to get started.

My Run Tracker, is the app that will be an ally to you in this journey. You can track the activities you do for the weight loss or for just being fit. My Run Tracker tracks your training using GPS and analyzes everything related, from the number of steps you run to the distance you covered and to the calories you have burned.<span><a href="https://play.google.com/store/apps/details?id=activity.com.myactivity2"> Read More</a></span></p>

                        <a href='https://play.google.com/store/apps/details?id=activity.com.myactivity2&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="my-4" style={{ width: "240px" }} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                    </div>

                </div>
            */}
            </div>
        );
    }
}
export default AppShowOffBuilder;

