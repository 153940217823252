import React from "react";


import github from '../../assets/github.png';
import playstore from '../../assets/playstore.png';
import youtube from '../../assets/icon_youtube.png';
import medium from '../../assets/medium.png';
import linkedin from '../../assets/linkedin.png';
import Typist from 'react-typist';

class AboutUsBuilder extends React.Component {

  state = {
    renderMsg: false,
  }

  onHeaderTyped = () => {
    this.setState({ renderMsg: true });
  }

  render() {
    return (

      <div className="container" >

        <div className="row mt-3">
        </div>
        <div className="row">
          <Typist
            className=" container mt-4 aboutUsText"
            cursor={{ hideWhenDone: true }}
            avgTypingDelay={100}
            startDelay={500}
            onTypingDone={this.onHeaderTyped}
          >
            <a > We are a bunch of losers who have been trying to change the world
            but we lost, so instead of changing the world we shifted our focus
            on making this same world a better place for everyone.
            </a>

          </Typist>
        </div>
        <div className="row mx-auto mt-4">
          {this.state.renderMsg ? (<p className="mx-auto">Write us on : <mark>team@thelosers.in</mark></p>) : null}
        </div>

        <div className="row px-2 mt-4">
          {this.state.renderMsg ? (<p >Follow me :</p>) : null}
        </div>

        <div className="row mt-4 mx-2">
          {this.state.renderMsg ?
            <div>
              <div className="row justify-content-center align-self-center">
                <a href='https://github.com/the-losers'>
                  <img style={{ width: '36px', height: '36px' }} className="mx-2" src={github}></img>
                </a>
                <a href='https://play.google.com/store/apps/dev?id=9027624324456106283&hl=en_IN'>
                  <img style={{ width: '36px', height: '36px' }} className="mx-2" src={playstore}></img>

                </a>
                
                <a href='https://www.linkedin.com/in/deepak-kumar-9812a3aa/'>
                  <img style={{ width: '36px', height: '36px' }} className="mx-2" src={linkedin}></img>
                </a>
                <a href='https://medium.com/@deepakypro/how-to-plot-a-graph-in-real-world-using-arcore-android-4f5f1007fb86'>
                  <img style={{ width: '36px', height: '36px' }} className="mx-2 my-1" src={medium}></img>
                </a>
                <a href='https://www.youtube.com/channel/UCpt5BC1XnbGRq2YS2rI9TnA?sub_confimation=1'>
                  <img style={{ width: '48px', height: '36px' }} className="mx-2" src={youtube}></img>

                </a>

              </div>
            </div> : null}


        </div>


        <div className="row mt-4">
          <hr style={{ backgroundColor: 'azure', width: '100%' }} />
        </div>

        <div className="row mt-4">
          {this.state.renderMsg ? (<p className="mx-auto">All Right Reserved.<mark> 2020</mark></p>) : null}
        </div>

      </div>

    );
  }
}
export default AboutUsBuilder;

