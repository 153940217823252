import React, { Component } from "react";
import RunTrackerBuilder from '../../components/RunTrackerBuilder/RunTrackerBuilder';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LOGO from '../../assets/my_run_tracker_logo.png';
 
class RunTracker extends Component {
  
  render() {
    

    return (
        <div>
            <Header logo={LOGO} home_link="https://play.google.com/store/apps/details?id=activity.com.myactivity2"/>
            <RunTrackerBuilder></RunTrackerBuilder>
            <Footer/>
        </div>
       
    );
  }
}

export default RunTracker;
